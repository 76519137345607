import HTMLParsedElement from "html-parsed-element"

const weekdays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
]

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

function pad(num, flag) {
  return flag ? num : `0${num}`.slice(-2)
}

function strftime(time, directiveFormat) {
  const day = time.getDay()
  const date = time.getDate()
  const month = time.getMonth()
  const year = time.getFullYear()
  const hour = time.getHours()
  const minute = time.getMinutes()
  const second = time.getSeconds()

  const directiveCaptures = /%(-?)([%aAbBcdeHIlmMpPSwyYZ])/g

  const directiveReplacements = (_match, flag, modifier) =>
    ({
      "%": () => "%",
      a: () => weekdays[day].slice(0, 3),
      A: () => weekdays[day],
      b: () => months[month].slice(0, 3),
      B: () => months[month],
      c: () => time.toString(),
      d: () => pad(date, flag),
      e: () => String(date),
      H: () => pad(hour, flag),
      I: () => pad(strftime(time, "%l")),
      l: () =>
        hour === 0 || hour === 12 ? String(12) : String((hour + 12) % 12),
      m: () => pad(month + 1, flag),
      M: () => pad(minute, flag),
      p: () => (hour > 11 ? "PM" : "AM"),
      P: () => (hour > 11 ? "pm" : "am"),
      S: () => pad(second),
      w: () => String(day),
      y: () => pad(year % 100, flag),
      Y: () => String(year),
      undefined: () => "",
    }[modifier]())

  return directiveFormat.replace(directiveCaptures, directiveReplacements)
}

class LocalTimeElement extends HTMLParsedElement {
  static get observedAttributes() {
    return ["datetime", "format"]
  }

  parsed = false
  date = null

  attributeChangedCallback(attrName, _oldValue, newValue) {
    if (attrName === "datetime") {
      const millis = Date.parse(newValue)

      isNaN(millis)
        ? this.date = null
        : this.date = new Date(millis)
    }

    if (this.parsed) {
      this.updateDate()
    }
  }

  parsedCallback() {
    this.parsed = true
    this.updateDate()
  }

  updateDate() {
    const text = this.getFormattedDate()

    if (text) {
      const node = this.children[0]
      node.textContent = text
    }
  }

  getFormattedDate() {
    const date = this.date

    if (!date) return

    const format = this.getAttribute("format")
    const formattedDatetime = strftime(date, format)

    return formattedDatetime
  }
}

if (!window.customElements.get("local-time")) {
  window.LocalTimeElement = LocalTimeElement
  window.customElements.define("local-time", LocalTimeElement)
}
