import HTMLParsedElement from "html-parsed-element"

const DEFAULT_ANCHOR_TOLERANCE = 1

class PersistentScroll extends HTMLParsedElement {
  parsedCallback() {
    this.restoreScrollPosition(this)

    this.addEventListener("scroll", () => {
      this.saveScrollPosition(this)
    })
  }

  saveScrollPosition() {
    const key = this.getStorageKey()

    let scrollPosition = this.scrollTop
    let anchorPosition
    let anchored = false

    if (this.anchor === "bottom") {
      anchorPosition = this.scrollHeight - this.clientHeight
      anchorPosition -= this.anchorTolerance

      if (scrollPosition >= anchorPosition) {
        anchored = true
      }
    } else {
      anchorPosition = 0
      anchorPosition += this.anchorTolerance

      if (scrollPosition <= anchorPosition) {
        anchored = true
      }
    }

    if (anchored) {
      sessionStorage.removeItem(key)
      return
    }

    sessionStorage.setItem(key, scrollPosition)
  }

  restoreScrollPosition() {
    const key = this.getStorageKey()
    const scrollPosition = sessionStorage.getItem(key)

    if (scrollPosition != null) {
      if (this.scrollTop != scrollPosition) {
        this.setAttribute("scroll-position-restored", scrollPosition)
        this.scrollTo(0, scrollPosition)
      }
    } else {
      if (this.anchor === "bottom") {
        let bottomPosition = this.scrollHeight - this.clientHeight

        this.scrollTo(0, bottomPosition)
      }
    }
  }

  getStorageKey() {
    let key = this.getAttribute("key")
    key ||= this.id

    if (!key) {
      throw new Error("persistent-scroll requires a key or id attribute")
    }

    return `persistent-scroll:${key}`
  }

  get anchor() {
    return this.getAttribute("anchor")
  }

  get anchorTolerance() {
    let tolerance = this.getAttribute("anchor-tolerance")

    if (tolerance == null) {
      return DEFAULT_ANCHOR_TOLERANCE
    }

    return parseInt(tolerance, 10)
  }
}

// This happens synchronously after turbo does its rendering, which is necessary
// to prevent a flash of the initial scroll position. The web component uses
// requestAnimationFrame to invoke the parsedCallback, which sometimes causes a
// delay in restoring the scroll position. - Aaron, Thu Jun 27 2024
window.addEventListener("turbo:render", () => {
  const elements = document.querySelectorAll("persistent-scroll")

  for (const element of elements) {
    element.restoreScrollPosition()
  }
})

if (!window.customElements.get("persistent-scroll")) {
  window.PersistentScroll = PersistentScroll
  window.customElements.define("persistent-scroll", PersistentScroll)
}
